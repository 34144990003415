import { APIFilter } from '@/utils/api'
import { TABLA, TCLASIFICACION_SERVICIO_CONTRATADO } from '@/utils/consts'

export default {
  async selectParteTrabajo (Vue, idparteTrabajo) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idparte_trabajo', idparteTrabajo)
    const resp = await Vue.$api.call(
      'parteTrabajo.selectAsignados',
      { filter: apiFilter }
    )
    return [
      resp.data.result.dataset[0],
      resp.data.result.metadata,
    ]
  },
  async selectProximasRevisiones (Vue, idsistema) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idsistema', idsistema)
    apiFilter.addExact('activar', 1)
    apiFilter.addGT('estado', 0)
    const resp = await Vue.$api.call(
      'sistemaMant.select',
      { filter: apiFilter }
    )
    return [
      resp.data.result.dataset,
      resp.data.result.metadata,
    ]
  },
  async selectDetails (Vue, idparteTrabajo, idordenTrabajo, idsistema, idtecnico, esTecnico, esComercial, esVigilante) {
    let apiCalls = []
    // material a instalar
    const apiFilterMaterialInstalar = new APIFilter()
    apiFilterMaterialInstalar
      .addExact('idparte_trabajo', idparteTrabajo)
      .addGT('estado', 0)
    apiCalls.push({ name: 'lparteTrabajo', method: 'lparteTrabajo.selectSubsis', params: { filter: apiFilterMaterialInstalar } })
    // material afectado
    const apiFilterMaterialAfectado = new APIFilter()
    apiFilterMaterialAfectado
      .addExact('idparte_trabajo', idparteTrabajo)
      .addGT('estado', 0)
    apiCalls.push({ name: 'parteTrabajoMatsist', method: 'parteTrabajoMatsist.selectSubsis', params: { filter: apiFilterMaterialAfectado } })
    // material retirado
    const apiFilterMaterialRetirado = new APIFilter()
    apiFilterMaterialRetirado
      .addExact('idparte_trabajo', idparteTrabajo)
      .addGT('estado', 0)
      .addIsNull('idalmacen', true)
    apiCalls.push({ name: 'parteTrabajoMatsistRetirado', method: 'parteTrabajoMatsist.selectSubsis', params: { filter: apiFilterMaterialRetirado } })
    // parte trabajo
    const apiFilterIdparte = new APIFilter()
    apiFilterIdparte
      .addExact('idparte_trabajo', idparteTrabajo)
      .addGT('estado', 0)
    apiCalls.push({
      name: 'selectLparteTrabajoSubsis',
      method: 'lparteTrabajo.selectSubsis',
      params: { filter: apiFilterIdparte },
    })
    apiCalls.push({
      name: 'selectParteTrabajoMatsistSubsis',
      method: 'parteTrabajoMatsist.selectSubsis',
      params: { filter: apiFilterIdparte },
    })
    // subsis
    const apiFilterSubsis = new APIFilter()
    apiFilterSubsis
      .addExact('idorden_trabajo', idordenTrabajo)
      .addGT('estado', 0)
    apiCalls.push({ name: 'ordenTrabajoSubsis', method: 'ordenTrabajoSubsis.select', params: { filter: apiFilterSubsis } })
    // servicios contratados
    const apiFilterCuotas = new APIFilter()
    apiFilterCuotas
      .addExact('idsistema', idsistema)
      .addExact('idtclasificacion_servicio', TCLASIFICACION_SERVICIO_CONTRATADO.clasificacion.mantenimiento)
      .addGT('estado', 0)
    apiCalls.push({ name: 'sistemaCuota', method: 'sistemaCuota.select', params: { filter: apiFilterCuotas } })
    // revisiones de sistema
    const apiFilterSistemaMant = new APIFilter()
    apiFilterSistemaMant
      .addExact('idsistema', idsistema)
      .addExact('activar', 1)
      .addGT('estado', 0)
    apiCalls.push({ name: 'sistemaMant', method: 'sistemaMant.selectCount', params: { filter: apiFilterSistemaMant } })
    // técnicos asignados
    const apiFilterTecnicosAsignados = new APIFilter()
    apiFilterTecnicosAsignados
      .addExact('idparte_trabajo', idparteTrabajo)
    apiCalls.push({ name: 'parteTrabajoTecnico', method: 'parteTrabajoTecnico.select', params: { filter: apiFilterTecnicosAsignados } })
    // Si tengo el parte asignado
    apiCalls.push({ name: 'parteTrabajoAsignado', method: 'parteTrabajo.asignado', params: { idparte_trabajo: idparteTrabajo, idtecnico: idtecnico } })
    apiCalls.push(Vue.$online.fichero.ApiCallSelectCount(idparteTrabajo, TABLA.parte_trabajo.idtabla, esTecnico, esComercial, esVigilante))
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectPersonasContactoCount (Vue, idsistema, idcliente) {
    return await Vue.$online.sistemaTelefono.selectContactosDelSistema(idsistema, idcliente)
  },
  async selectTiempoTrabajadoCount (Vue, idparteTrabajo) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idparte_trabajo', idparteTrabajo)
      .addGT('estado', 0)
    const resp = await Vue.$api.call('tiempoTrabajado.select', { filter: apiFilter })
    let tiempoManoObra = 0
    let tiempoDesplazamiento = 0
    for (const tiempo of resp.data.result.dataset) {
      if (tiempo.es_desplazamiento) {
        tiempoDesplazamiento += tiempo.ffin.getTime() - tiempo.finicio.getTime()
      } else {
        tiempoManoObra += tiempo.ffin.getTime() - tiempo.finicio.getTime()
      }
    }
    return {
      count: resp.data.result.dataset.length,
      tiempoManoObra,
      tiempoDesplazamiento,
    }
  },
  async geolocalizar (Vue, idclienteDireccion, latitud, longitud) {
    const resp = await Vue.$api.call(
      'clienteDireccion.update', {
        values: {
          idcliente_direccion: idclienteDireccion,
          latitud,
          longitud,
        },
      })
    return resp.data.result
  },
}
